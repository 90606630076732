var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.popupParam.materialCd
                            ? _c("c-btn", {
                                attrs: {
                                  label: "삭제",
                                  editable: _vm.editable,
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.removeData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "품명",
                            name: "materialName",
                          },
                          model: {
                            value: _vm.data.materialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialName", $$v)
                            },
                            expression: "data.materialName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "규격",
                            name: "materialStandard",
                          },
                          model: {
                            value: _vm.data.materialStandard,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialStandard", $$v)
                            },
                            expression: "data.materialStandard",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "자재관리번호",
                            name: "materialNo",
                          },
                          model: {
                            value: _vm.data.materialNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialNo", $$v)
                            },
                            expression: "data.materialNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            codeGroupCd: "MATERIAL_UNIT_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "materialUnitCd",
                            label: "단위",
                          },
                          model: {
                            value: _vm.data.materialUnitCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialUnitCd", $$v)
                            },
                            expression: "data.materialUnitCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "대표단가",
                            name: "price",
                            type: "number",
                            suffix: "원",
                          },
                          model: {
                            value: _vm.data.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "price", $$v)
                            },
                            expression: "data.price",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "재질",
                            name: "materialQuality",
                          },
                          model: {
                            value: _vm.data.materialQuality,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialQuality", $$v)
                            },
                            expression: "data.materialQuality",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대표제조사",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.data.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorCd", $$v)
                            },
                            expression: "data.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-material", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대체 자재",
                            name: "supplantMaterialCd",
                            supplantMaterialCd: _vm.popupParam.materialCd,
                            plantCd: _vm.popupParam.plantCd,
                          },
                          model: {
                            value: _vm.data.supplantMaterialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "supplantMaterialCd", $$v)
                            },
                            expression: "data.supplantMaterialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: { editable: _vm.editable, name: "plantCd" },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "안전 재고량",
                            type: "number",
                            name: "safetyStock",
                          },
                          model: {
                            value: _vm.data.safetyStock,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "safetyStock", $$v)
                            },
                            expression: "data.safetyStock",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "재 주문점(ROP)",
                            type: "number",
                            name: "ropStock",
                          },
                          model: {
                            value: _vm.data.ropStock,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "ropStock", $$v)
                            },
                            expression: "data.ropStock",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "현재 재고량",
                            type: "number",
                            name: "realStock",
                          },
                          model: {
                            value: _vm.data.realStock,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "realStock", $$v)
                            },
                            expression: "data.realStock",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "최초작성자",
                            name: "regUserName",
                            type: "regUserName",
                          },
                          model: {
                            value: _vm.data.regUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "regUserName", $$v)
                            },
                            expression: "data.regUserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "최초작성일시",
                            name: "regDtStrTime",
                            type: "regDtStrTime",
                          },
                          model: {
                            value: _vm.data.regDtStrTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "regDtStrTime", $$v)
                            },
                            expression: "data.regDtStrTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "최근수정자",
                            name: "chgUserName",
                            type: "chgUserName",
                          },
                          model: {
                            value: _vm.data.chgUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "chgUserName", $$v)
                            },
                            expression: "data.chgUserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "최근수정일시",
                            name: "chgDtStrTime",
                            type: "chgDtStrTime",
                          },
                          model: {
                            value: _vm.data.chgDtStrTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "chgDtStrTime", $$v)
                            },
                            expression: "data.chgDtStrTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
            [
              _c(
                "c-table",
                {
                  ref: "grid1",
                  attrs: {
                    title: "자재 입고 이력",
                    columns: _vm.grid1.columns,
                    data: _vm.data.materialInData,
                    gridHeight: _vm.grid1.height,
                    editable: _vm.editable && !_vm.disabled,
                    pagePerRow: { pageNumber: 10 },
                    hideBottom: false,
                    usePaging: true,
                    isExcelDown: true,
                    filtering: false,
                    isFullScreen: true,
                    columnSetting: false,
                    topBorderClass: "topcolor-orange",
                    selection: "multiple",
                    rowKey: "materialInCd",
                  },
                  on: { "table-data-change": _vm.tableDataChange },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.materialCd
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addrow },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.popupParam.materialCd
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.removeRow },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c("c-table", {
                ref: "grid2",
                attrs: {
                  title: "자재 출고 이력(W/O)",
                  columns: _vm.grid2.columns,
                  data: _vm.data.materialOutData,
                  gridHeight: _vm.grid2.height,
                  editable: false,
                  pagePerRow: { pageNumber: 10 },
                  hideBottom: false,
                  usePaging: true,
                  isExcelDown: true,
                  filtering: false,
                  isFullScreen: true,
                  columnSetting: false,
                  topBorderClass: "topcolor-orange",
                },
                on: { linkClick: _vm.linkClick },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
            [
              _c("c-table", {
                ref: "grid3",
                attrs: {
                  title: "설비 목록",
                  columns: _vm.grid5.columns,
                  data: _vm.grid5.data,
                  gridHeight: _vm.grid5.height,
                  editable: _vm.editable && !_vm.disabled,
                  pagePerRow: { pageNumber: 10 },
                  hideBottom: false,
                  usePaging: true,
                  isExcelDown: true,
                  filtering: true,
                  isFullScreen: true,
                  columnSetting: false,
                  topBorderClass: "topcolor-orange",
                },
                on: { linkClick: _vm.equipLinkClick },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c(
                "c-table",
                {
                  ref: "grid4",
                  attrs: {
                    title: "자재 출고 이력(직접추가)",
                    columns: _vm.grid4.columns,
                    data: _vm.data.materialOutInputData,
                    gridHeight: _vm.grid4.height,
                    editable: _vm.editable && !_vm.disabled,
                    pagePerRow: { pageNumber: 10 },
                    hideBottom: false,
                    usePaging: true,
                    isExcelDown: true,
                    filtering: false,
                    isFullScreen: true,
                    columnSetting: false,
                    selection: "multiple",
                    rowKey: "materialOutCd",
                    topBorderClass: "topcolor-orange",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.materialCd
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addrow2 },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.popupParam.materialCd
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.removeRow2 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7",
              staticStyle: { "padding-top": "20px !important" },
            },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable,
                  label: "자재 사진 및 첨부파일",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c("c-table", {
                ref: "grid3",
                attrs: {
                  title: "자재 실사이력",
                  columns: _vm.grid3.columns,
                  data: _vm.grid3.data,
                  gridHeight: _vm.grid3.height,
                  editable: _vm.editable && !_vm.disabled,
                  pagePerRow: { pageNumber: 10 },
                  hideBottom: false,
                  usePaging: true,
                  isExcelDown: true,
                  filtering: true,
                  isFullScreen: true,
                  columnSetting: false,
                  topBorderClass: "topcolor-orange",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }