<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.materialCd" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="품명"
                  name="materialName"
                  v-model="data.materialName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="규격"
                  name="materialStandard"
                  v-model="data.materialStandard">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="자재관리번호"
                  name="materialNo"
                  v-model="data.materialNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="MATERIAL_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="materialUnitCd"
                  label="단위"
                  v-model="data.materialUnitCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="대표단가"
                  name="price"
                  type="number"
                  suffix="원"
                  v-model="data.price">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="재질"
                  name="materialQuality"
                  v-model="data.materialQuality">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-vendor
                  :editable="editable"
                  label="대표제조사"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-material
                  :editable="editable"
                  label="대체 자재"
                  name="supplantMaterialCd"
                  :supplantMaterialCd="popupParam.materialCd"
                  :plantCd="popupParam.plantCd"
                  v-model="data.supplantMaterialCd">
                </c-material>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :editable="editable"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="안전 재고량"
                  type="number"
                  name="safetyStock"
                  v-model="data.safetyStock">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="재 주문점(ROP)"
                  type="number"
                  name="ropStock"
                  v-model="data.ropStock">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="false"
                  label="현재 재고량"
                  type="number"
                  name="realStock"
                  v-model="data.realStock">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최초작성자"
                  name="regUserName"
                  type="regUserName"
                  v-model="data.regUserName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최초작성일시"
                  name="regDtStrTime"
                  type="regDtStrTime"
                  v-model="data.regDtStrTime">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최근수정자"
                  name="chgUserName"
                  type="chgUserName"
                  v-model="data.chgUserName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최근수정일시"
                  name="chgDtStrTime"
                  type="chgDtStrTime"
                  v-model="data.chgDtStrTime">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="grid1"
            title="자재 입고 이력"
            :columns="grid1.columns"
            :data="data.materialInData"
            :gridHeight="grid1.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="false"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
            selection="multiple"
            rowKey="materialInCd"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable && popupParam.materialCd" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable && popupParam.materialCd" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid2"
            title="자재 출고 이력(W/O)"
            :columns="grid2.columns"
            :data="data.materialOutData"
            :gridHeight="grid2.height"
            :editable="false"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="false"
            :isFullScreen="true"
            :columnSetting="false"
            @linkClick="linkClick"
            topBorderClass="topcolor-orange"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="grid3"
            title="설비 목록"
            :columns="grid5.columns"
            :data="grid5.data"
            :gridHeight="grid5.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            @linkClick="equipLinkClick"
            topBorderClass="topcolor-orange"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid4"
            title="자재 출고 이력(직접추가)"
            :columns="grid4.columns"
            :data="data.materialOutInputData"
            :gridHeight="grid4.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="false"
            :isFullScreen="true"
            :columnSetting="false"
            selection="multiple"
            rowKey="materialOutCd"
            topBorderClass="topcolor-orange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" icon="add" @btnClicked="addrow2" v-if="editable && popupParam.materialCd" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRow2" v-if="editable && popupParam.materialCd" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" style="padding-top:20px !important;">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="자재 사진 및 첨부파일">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid3"
            title="자재 실사이력"
            :columns="grid3.columns"
            :data="grid3.data"
            :gridHeight="grid3.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
          >
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        materialCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MATERIAL_FILE',
        taskKey: '',
      },
      saveUrl: transactionConfig.sp.material.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      inDeleteUrl: '',
      materialoutUrl: '',
      outDeleteUrl: '',
      equipmentUrl: '',
      materialhisUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      equipmentList: [],
      data: {
        plantCd: null,  // 사업장코드
        materialNo: '',
        materialCd: '',  // 정비자재 일련번호
        materialName: '',  // 품명
        materialUnitCd: null,  // 자재 단위
        materialStandard: '',  // 자재 규격
        materialQuality: '',  // 자재 재질
        price: null,  // 대표단가
        vendorCd: '',  // 제조사
        safetyStock: '',  // 안전재고량
        realStock: '',  // 현재재고량
        ropStock: '',  // 재주문점(ROP)
        delFlag: 'N',  // 삭제여부
        supplantMaterialCd: '',  // 대체자재
        materialInData: [], // 입고이력
        materialOutData: [],
        materialOutInputData: [],
      },
      grid1: {
        columns: [
          {
            name: 'inDt',
            field: 'inDt',
            label: '입고일',
            align: 'center',
            type: 'date',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'vendorCd',
            field: 'vendorCd',
            label: '공급업체',
            align: 'left',
            type: 'vendor',
            sortable: true,
          },
          {
            name: 'inStock',
            field: 'inStock',
            label: '입고수량',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inUnitPrice',
            field: 'inUnitPrice',
            label: '단가',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inTotalPrice',
            field: 'inTotalPrice',
            label: '총비용',
            type: 'cost',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
        height: '360px',
      },
      grid2: {
        columns: [
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'outDt',
            field: 'outDt',
            label: '출고일',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'workOrderName',
            field: 'workOrderName',
            label: '작업명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:150px',
          },
          {
            name: 'outStock',
            field: 'outStock',
            label: '출고수량',
            type: 'cost',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '360px',
      },
      grid4: {
        columns: [
        ],
        data: [],
        height: '360px',
      },
      grid3: {
        columns: [
          {
            name: 'conductDt',
            field: 'conductDt',
            label: '실사일',
            align: 'center',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'conductStock',
            field: 'conductStock',
            label: '실사재고량',
            style: 'width:200px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'calcStock',
            field: 'calcStock',
            label: '재고 보정량',
            type: 'cost',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: '360px',
      },
      grid5: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '360px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sp.material.get.url;
      this.insertUrl = transactionConfig.sp.material.insert.url;
      this.updateUrl = transactionConfig.sp.material.update.url;
      this.deleteUrl = transactionConfig.sp.material.delete.url;
      this.inDeleteUrl = transactionConfig.sp.materialin.delete.url;
      this.outDeleteUrl = transactionConfig.sp.materialout.delete.url;
      this.materialoutUrl = selectConfig.sp.materialout.list.url;
      this.materialhisUrl = selectConfig.sp.conducthis.list.url;
      this.equipmentUrl = selectConfig.mdm.equipment.list.url;
      
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.materialCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {
          materialCd: this.popupParam.materialCd,
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.materialCd)
          this.getConductHis();
        },);
        
        this.$http.url = this.equipmentUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          materialCd: this.popupParam.materialCd,
        };
        this.$http.request((_result) => {
          this.grid5.data = _result.data

          this.setColumn();
        },);
      }
    },
    setColumn() {
      this.grid4.columns = [
        {
          name: 'equipmentCd',
          field: 'equipmentCd',
          label: '사용설비',
          align: 'center',
          sortable: true,
          style: 'width:180px',
          type: 'select',
          itemText: 'equipmentName',
          itemValue: 'equipmentCd',
          comboItems: this.grid5.data,
        },
        {
          name: 'outDt',
          field: 'outDt',
          label: '출고일',
          type: 'date',
          align: 'center',
          sortable: true,
          style: 'width:150px',
        },
        {
          name: 'outStock',
          field: 'outStock',
          label: '출고수량',
          type: 'number',
          style: 'width:100px',
          sortable: true,
        },
      ]
    },
    getConductHis() {
      if (this.popupParam.materialCd) {
        this.$http.url = this.materialhisUrl;
        this.$http.param = {materialCd: this.popupParam.materialCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid3.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.materialCd) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              
              let saveData = this.data.materialInData.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.data.materialInData = saveData;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.materialCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.materialCd = result.data.materialCd
      this.$set(this.attachInfo, 'taskKey', this.popupParam.materialCd)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addrow() {
      this.data.materialInData.splice(0, 0, {
        plantCd: this.data.plantCd,  // 사업장코드
        materialInCd: '',  // 정비자재 입고이력 일련번호
        materialCd: this.popupParam.materialCd,  // 정비자재 일련번호
        inDt: this.$comm.getToday(),  // 입고일
        vendorCd: '',  // 공급업체
        inStock: '',  // 입고수량
        inUnitPrice: '',  // 단가
        inTotalPrice: '',  // 총 비용
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeRow() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.inDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.materialInData = this.$_.reject(this.data.materialInData, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.data.materialOutInputData.splice(0, 0, {
        plantCd: this.data.plantCd,  // 사업장코드
        materialOutCd: uid(),  // 정비자재 입고이력 일련번호
        materialCd: this.popupParam.materialCd,  // 정비자재 일련번호
        equipmentCd: null,  // 정비자재 일련번호
        outDt: this.$comm.getToday(),  // 입고일
        outStock: '',  // 공급업체
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeRow2() {
      let selectData = this.$refs['grid4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.outDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.materialOutInputData = this.$_.reject(this.data.materialOutInputData, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange(props) {
      if (props.row['inUnitPrice'] > 0 && props.row['inStock'] > 0) {
        this.$set(props.row, 'inTotalPrice', props.row['inStock'] * props.row['inUnitPrice']);
      }
    },
    linkClick(row) {
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        workResultId: row ? row.workResultId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
        copyFlag: row ? row.copyFlag : 'N',
        firstCopy: row ? (row.firstCopy ? row.firstCopy : false) : false,
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    equipLinkClick(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
        isBom: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>